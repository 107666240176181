<template>
  <div>
    <!-- 封面图 -->
    <div class="banner" :style="articleCover">
      <div class="article-info-container">
        <!-- 文章标题 -->
        <div class="article-title">{{ article.title }}</div>
        <div class="article-info">
          <div class="first-line">
            <!-- 发表时间 -->
            <span>
              <i class="iconfont iconrili" />
              发表于 {{ article.createTime | date }}
            </span>
            <span class="separator">|</span>
            <!-- 发表时间 -->
            <span>
              <i class="iconfont icongengxinshijian" />
              更新于
              <template v-if="article.updateTime">
                {{ article.updateTime | date }}
              </template>
              <template v-else>
                {{ article.createTime | date }}
              </template>
            </span>
            <span class="separator">|</span>
            <!-- 文章分类 -->
            <span class="article-category">
              <i class="iconfont iconfenlei1" />
              <router-link :to="'/categories/' + 1">
                {{ article.categoryName }}
              </router-link>
            </span>
          </div>
          <div class="second-line">
            <!-- 字数统计 -->
            <span>
              <i class="iconfont iconzishu" />
              字数统计: {{ wordNum | num }}
            </span>
            <span class="separator">|</span>
            <!-- 阅读时长 -->
            <span>
              <i class="iconfont iconshijian" />
              阅读时长: {{ readTime }}
            </span>
          </div>
          <div class="third-line">
            <span class="separator">|</span>
            <!-- 阅读量 -->
            <span>
              <i class="iconfont iconliulan" /> 阅读量: {{ article.views }}
            </span>
            <span class="separator">|</span>
            <!-- 评论量 -->
            <span>
              <i class="iconfont iconpinglunzu1" />评论数:
              <template v-if="commentCount">{{ commentCount }}</template>
              <template v-else>0</template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <v-row class="article-container">
      <v-col md="9" cols="12">
        <v-card class="article-wrapper">
          <article
            id="write"
            class="article-content markdown-body"
            v-html="article.htmlContent"
            v-highlight
            ref="article"
          />
          <!-- 版权声明 -->
          <div class="aritcle-copyright">

            <div>
              <span>文章作者：</span>
              <font>{{ article.authorName }}</font>
            </div>

            <div>
              <span>创作类型：</span>
              <font v-if="article.articleType === 2">转载</font>
              <font v-else>原创</font>
            </div>

            <div v-if="article.articleType === 2">
              <span>原文链接：</span>
              <a :href="article.curationLink" target="_blank"
                >{{ article.curationLink }}
              </a>
            </div>

            <div>
              <span>版权声明：</span>本博客所有文章除特别声明外，均采用
              <a
                href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                target="_blank"
                >CC BY-NC-SA 4.0</a
              >许可协议。转载请注明文章出处。
            </div>
          </div>

          <!-- 转发 -->
          <!--<div class="article-operation">
            <div class="tag-container">
              <router-link v-for="item of article.labelList" :key="item.labelId" :to="'/tags/' + item.labelId">
                {{ item.name }}
              </router-link>
            </div>
            <share style="margin-left:auto" :config="config" />
          </div>-->

          <!-- 点赞打赏等 -->
          <div class="article-reward">
            <el-button @click="like" type="primary" round icon="el-icon-thumb" >{{likeDesc}}</el-button>
            <el-button @click="favorite" type="primary" round icon="el-icon-star-off" style="margin-left: 20px;">{{favoriteDesc}}</el-button>
            <el-button type="primary" round icon="el-icon-coin" style="margin-left: 20px;">打赏</el-button>
<!--            <a class="reward-btn">
              &lt;!&ndash; 打赏按钮 &ndash;&gt;
              <i class="iconfont iconerweima" /> 打赏
              &lt;!&ndash; 二维码 &ndash;&gt;
              <div class="animated fadeInDown reward-main">
                <ul class="reward-all">
                  <li class="reward-item">
                    <img class="reward-img" :src="websiteConfig.wxMoneyImg"  alt=""/>
                    <div class="reward-desc">微信</div>
                  </li>
                  <li class="reward-item">
                    <img class="reward-img" :src="websiteConfig.zfbMoneyImg"  alt=""/>
                    <div class="reward-desc">支付宝</div>
                  </li>
                </ul>
              </div>
            </a>-->
          </div>

          <!--    上一篇、下一篇      -->
          <div class="pagination-post">
            <!-- 上一篇 -->
            <div :class="isFull(previousArticle.articleId)" v-if="previousArticle">
              <router-link :to="'/articles/' + previousArticle.articleId" tag="a" target="_blank">
                <img class="post-cover" :src="previousArticle.cover" />
                <div class="post-info">
                  <div class="label">上一篇</div>
                  <div class="post-title">
                    {{ getHandlerArticleTitle(previousArticle.title) }}
                  </div>
                </div>
              </router-link>
            </div>
            <!-- 下一篇 -->
            <div :class="isFull(nextArticle.articleId)" v-if="nextArticle">
              <router-link :to="'/articles/' + nextArticle.articleId" tag="a" target="_blank">
                <img class="post-cover" :src="nextArticle.cover" />
                <div class="post-info" style="text-align: right">
                  <div class="label">下一篇</div>
                  <div class="post-title">
                    {{ getHandlerArticleTitle(nextArticle.title) }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <!-- 推荐文章 -->
          <div class="recommend-container" v-if="recommendArticleList">
            <div class="recommend-title">
              <v-icon size="20" color="#4c4948">mdi-thumb-up</v-icon>
              相关推荐
            </div>
            <div class="recommend-list">
              <div class="recommend-item" v-for="item of recommendArticleList" :key="item.articleId">
                <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                  <img class="recommend-cover" :src="item.cover" />
                  <div class="recommend-info">
                    <div class="recommend-date">
                      <i class="iconfont iconrili" />
                      {{ item.createTime | date }}
                    </div>
                    <div>{{ getHandlerArticleTitle(item.title) }}</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <!-- 分割线 -->
          <hr />
          <!-- 评论 -->
          <comment
            @doSend="doSend"
            @doChidSend="doChidSend"
            :commentList="commentList"
            :label="userName"
            :commentNum="commentCount"
            placeholder="请遵守社区规定，文明发言..."
            :avatar="userAvatar"

          >
          </comment>
          <!--    加载更多    -->
          <el-button v-if="commentList"
                     type="info"
                     :disabled="moreTitle === '没有更多了...' "
                     round style="margin-left: 50%;margin-top: 10px;"
                     :loading="jiazailoading"
                     @click="more">{{moreTitle}}</el-button>
          <br />
          <br />
        </v-card>
      </v-col>
      <!-- 侧边功能 -->
      <v-col md="3" cols="12" class="d-md-block d-none">
        <div style="position: sticky;top: 20px;">
          <!-- 文章目录 -->
          <v-card class="right-container">
            <div class="right-title">
              <i class="iconfont iconhanbao" style="font-size:16.8px" />
              <span style="margin-left:10px">目录</span>
            </div>
            <div id="toc" />
          </v-card>
          <!-- 最新文章 -->
          <v-card class="right-container" style="margin-top:20px">
            <div class="right-title">
              <i class="iconfont icongengxinshijian" style="font-size:16.8px" />
              <span style="margin-left:10px">最新文章</span>
            </div>
            <div class="article-list">
              <div class="article-item" v-for="item of newArticleList" :key="item.articleId">
                <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank" class="content-cover">
                  <img :src="item.cover" alt="" />
                </router-link>
                <div class="content">
                  <div class="content-title">
                    <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                      <span class="showInline">{{ item.title }}</span>

                    </router-link>
                  </div>
                  <div class="content-time">{{ item.createTime | date }}</div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div></div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import tocbot from "tocbot";
import { queryWebsite } from "../../api/index";
import comment from "bright-comment";
import { getCommentList,
  sendComment } from "@/api/comment";
import { getArticleLike,
  delArticleLike,
  setArticleLike,
  getUserId,
  getUserAvatar,
  getUserName,
  getArticleFavorite,
  setArticleFavorite,
  delArticleFavorite,
  setArticleViews,
  getArticleViews } from "@/utils/cookieUtil";
import {
  likeArticle,
  collectArticle,
  articleInfo,
  articleViews,
  getNextAndPreviousArticle,
  recommendArticle,
  getNewsArticleList,
  isFavorite
} from "@/api/article";
import { handlerArticleSummary, handlerArticleTitle } from "@/utils/commonUtil";

export default {
  components: {
    comment
  },

  created() {
    this.init();
    this.fetchArticleDetail();
    this.getNextAndPreviousArticle();
    this.getRecommendArticleList();
    this.getNewArticleList();
    this.views();
    this.fetchComment();
  },

  data: function() {
    return {
      websiteConfig: {
        zfbMoneyImg: "",
        wxMoneyImg: ""
      },
      commentListParam: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        articleId: ""
      },
      config: {
        sites: ["qzone", "wechat", "weibo", "qq"]
      },
      likeDesc: "点赞",
      favoriteDesc: "收藏",
      commentCount: 0,
      query: {},
      imgList: [],
      articleId: this.$route.params.articleId,
      dialogVisible: false,
      code: null,
      isCheck: false,
      article: {
        thumhup: "",
        htmlContent: "",
        views: 0,
        createTime: "",
        categoryName: "",
        nextArticle: {
          id: 0,
          articleCover: ""
        },
        lastArticle: {
          id: 0,
          articleCover: ""
        },
        category: {
          id: 0,
          name: ""
        },
        recommendArticleList: [],
        newestArticleList: []
      },
      user: {},
      newestArticleList: [],
      commentList: [],
      count: 0,
      wordNum: "",
      readTime: "",
      articleHref: window.location.href,
      img: process.env.VUE_APP_IMG_API,
      clipboard: null,
      userId: "",
      userName: "",
      userAvatar: "",
      nextArticle: {},
      previousArticle: {},
      recommendArticleList: [],
      newArticleList: [],
      moreTitle: "加载更多",
      jiazailoading: false,
      pageNo: 2,
      favoriteFlag: false,
      codes: []
    }
  },
  methods: {
    // 初始化
    init() {
      if (getUserId()) {
        this.userId = getUserId();
      }
      if (getUserAvatar()) {
        this.userAvatar = getUserAvatar();
      }
      if (getUserName()) {
        this.userName = getUserName();
      }
      if (getArticleLike(this.articleId)) {
        this.likeDesc = "取消点赞";
      } else {
        this.likeDesc = "点赞";
      }

      if (getArticleFavorite(this.articleId)) {
        this.favoriteDesc = "取消收藏";
      } else {
        this.favoriteDesc = "收藏";
      }

      this.userFavoriteArticle();
    },

    // 判断当前用户是否已收藏了该文章
    userFavoriteArticle() {
      isFavorite(this.articleId).then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.favoriteDesc = '取消收藏';
          }
        }
      })
    },

    // 根据文章id查询评论信息
    fetchComment() {
      this.commentListParam.articleId = this.articleId;
      getCommentList(this.commentListParam).then(response => {
        if (response.code === 200) {
          this.commentList = response.data.list;
          this.commentCount = response.data.total;
          if (this.commentCount <= this.commentListParam.pageSize) {
            this.moreTitle = "没有更多了..."
          }
        }
      });
    },

    // 获取最新文章列表
    getNewArticleList() {
      getNewsArticleList().then(res => {
        if (res.code === 200) {
          this.newArticleList = res.data;
        }
      })
    },

    // 获取当前文章的上一篇和下一篇
    getNextAndPreviousArticle() {
      getNextAndPreviousArticle(this.articleId).then(res => {
        if (res.code === 200) {
          this.nextArticle = res.data.next;
          this.previousArticle = res.data.previous;
        }
      })
    },

    // 获取推荐文章
    getRecommendArticleList() {
      recommendArticle(this.articleId).then(res => {
        if (res.code === 200) {
          this.recommendArticleList = res.data;
        }
      })
    },

    // 发表评论
    doSend(content) {
      if (!this.userId) {
        this.$toast({ type: "error", message: "请先登录" });
        return false;
      }
      // 已登录
      if (!content) {
        this.$toast({ type: "error", message: "评论内容为空" });
        return false;
      }

      let commentParam = {
        parentId: 0,
        commentUserId: this.userId,
        targetUserId: 0,
        content,
        articleId: this.articleId
      }

      sendComment(commentParam).then(res => {
        if (res.code === 200) {
          this.$toast({ type: "success", message: "评论成功" });
          this.fetchComment();
        } else {
          this.$toast({ type: "error", message: res.message });
        }
      })
    },

    // 加载更多评论
    more() {
      if (this.moreTitle === "加载更多") {
        this.commentListParam.pageNo = this.pageNo;
        getCommentList(this.commentListParam).then(res => {
          if (res.code === 200) {
            this.commentList = this.commentList.concat(res.data.list);
            const a = Math.ceil(res.data.total / this.commentListParam.pageSize)
            if (a === this.commentListParam.pageNo) {
              this.moreTitle = "没有更多了..."
            } else {
              this.pageNo++;
            }
          }
        })
      }
    },

    // 评论内容, 被评论用户id, 父级评论id
    doChidSend(content, bid, pid) {
      console.log(content, bid, pid)
      if (!this.userId) {
        this.$toast({ type: "error", message: "请先登录" });
        return false;
      }

      if (!content) {
        this.$toast({ type: "error", message: "评论内容为空" });
        return false;
      }

      const commentParam = {
        parentId: pid,
        commentUserId: this.userId,
        targetUserId: bid,
        content,
        articleId: this.articleId
      }

      sendComment(commentParam).then(res => {
        if (res.code === 200) {
          this.fetchComment();
          this.$toast({ type: "success", message: "回复成功" });
        } else {
          this.$toast({ type: "error", message: res.message });
        }
      })
    },

    // 查询网站配置信息
    fetchWebsiteConfig() {
      queryWebsite().then(response => {
        if (response.code === 200) {
          this.websiteConfig = response.data;
        }
      });
    },

    // 查询文章详情
    fetchArticleDetail() {
      articleInfo(this.articleId).then(response => {
        if (response.code === 200) {
          this.article = response.data;
          document.title = '西瓜博客-' + response.data.title;
          // 将markdown转换为Html
          this.$nextTick(() => {
            // 统计文章字数
            this.wordNum = this.deleteHTMLTag(this.article.mdContent).length;
            // 计算阅读时间
            if (this.wordNum <= 400) {
              this.readTime = 1 + "分钟";
            } else {
              this.readTime = Math.round(this.wordNum / 400) + "分钟";
            }
            // 添加代码复制功能
            this.clipboard = new Clipboard(".copy-btn");
            this.clipboard.on("success", () => {
              this.$toast({ type: "success", message: "复制成功" });
            });
            // 添加文章生成目录功能
            let nodes = this.$refs.article.children;
            if (nodes.length) {
              for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i];
                let reg = /^H[1-4]{1}$/;
                if (reg.exec(node.tagName)) {
                  node.id = i;
                }
              }
            }
            tocbot.init({
              tocSelector: "#toc", //要把目录添加元素位置，支持选择器
              contentSelector: ".article-content", //获取html的元素
              headingSelector: "h1, h2, h3,h4", //要显示的id的目录
              hasInnerContainers: true,
              onClick: function(e) {
                e.preventDefault();
              }
            });

            // 添加图片预览功能
            const imgList = this.$refs.article.getElementsByTagName("img");
            for (let i = 0; i < imgList.length; i++) {
              this.imgList.push(imgList[i].src);
              imgList[i].addEventListener("click", function(e) {
                this.previewImg(e.target.currentSrc);
              });
            }

            //修改代码样式和添加复制按钮
            //this.getCodes();
          });
        } else {
          this.$router.push("/404")
        }
      });
    },

    // 修改代码样式和添加复制按钮
    getCodes() {
      this.codes = document.querySelectorAll("pre");
      console.log("codes", this.codes)
      if (this.codes.length > 0) {
        this.codes.forEach(item => {
          if (item.offsetHeight !== 0) {
            return this.codeStyleInit();
          } else {
            for (let j = 0; j < this.codes.length; j++) {
              if (this.codes[j].offsetHeight !== 0) {
                return this.codeStyleInit();
              }
            }
          }
        })
      }
    },

    codeStyleInit() {
      this.codes.forEach((item, index) => {
        let icon =
            `<div class="mac-icon">` +
            `<span class="mac-icon-red"></span>` +
            `<span class="mac-icon-yellow"></span>` +
            `<span class="mac-icon-green"></span>` +
            `<i class="iconfont icon-fuzhi copy-button hand-style"></i>` +
            `</div>`;
        item.insertAdjacentHTML("afterbegin", icon);
        let value = item.lastElementChild.innerText;
        // 获取复制元素
        let copyButton =
            item.firstElementChild.getElementsByClassName("copy-button")[0];
        copyButton.onclick = function () {
          const clipboard = new Clipboard(".copy-button", {
            text: () => value,
          });
          clipboard.on("success", () => {
            clipboard.destroy();
          });
          clipboard.on("error", () => {
            //proxy.$modal.msgError("复制失败");
            clipboard.destroy();
          });
        };
      });
    },

    // 点赞
    like() {
      // 判断是否登录
      if (!getUserId()) {
        this.$toast({ type: "error", message: "请先登录" });
        return;
      }
      // 发送请求
      const like = getArticleLike(this.articleId);
      let likeData = {
        articleId: this.articleId,
        likeStatus: 1
      }
      if (like) {
        // 取消点赞
        likeData.likeStatus = 0;
        likeArticle(likeData).then(res => {
          if (res.code === 200) {
            delArticleLike(this.articleId);
            this.$toast({ type: "success", message: "取消点赞成功" });
            this.likeDesc = "点赞";
          } else {
            this.$toast({ type: "error", message: res.message });
          }
        })
      } else {
        // 点赞
        likeArticle(likeData).then(res => {
          if (res.code === 200) {
            setArticleLike(this.articleId);
            this.$toast({ type: "success", message: "点赞成功" });
            this.likeDesc = "取消点赞";
          } else {
            this.$toast({ type: "error", message: res.message });
          }
        })
      }
    },

    // 收藏
    favorite() {
      // 判断是否登录
      if (!getUserId()) {
        this.$toast({ type: "error", message: "请先登录" });
        return;
      }

      const favorite = getArticleFavorite(this.articleId);
      let favoriteData = {
        favoriteStatus: 1,
        articleId: this.articleId
      }
      if (favorite) {
        favoriteData.favoriteStatus = 0;
        collectArticle(favoriteData).then(res => {
          if (res.code === 200) {
            delArticleFavorite(this.articleId);
            this.$toast({ type: "success", message: "取消收藏成功" });
            this.favoriteDesc = "收藏";
          } else {
            this.$toast({ type: "error", message: res.message });
          }
        })
      } else {
        collectArticle(favoriteData).then(res => {
          if (res.code === 200) {
            setArticleFavorite(this.articleId);
            this.$toast({ type: "success", message: "收藏成功" });
            this.favoriteDesc = "取消收藏";
          } else {
            this.$toast({ type: "error", message: res.message });
          }
        })
      }

    },

    // 阅读量
    views() {
      if (getArticleViews(this.articleId)) {
        return;
      }
      articleViews(this.articleId).then(res => {
        if (res.code === 200) {
          setArticleViews(this.articleId);
        }
      })
    },

    markdownToHtml(article) {
      const MarkdownIt = require("markdown-it");
      const hljs = require("highlight.js");
      const md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function(str, lang) {
          // 当前时间加随机数生成唯一的id标识
          var d = new Date().getTime();
          if (
            window.performance &&
            typeof window.performance.now === "function"
          ) {
            d += performance.now();
          }
          const codeIndex = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function(c) {
              var r = (d + Math.random() * 16) % 16 | 0;
              d = Math.floor(d / 16);
              return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
          );
          // 复制功能主要使用的是 clipboard.js
          let html = `<button class="copy-btn iconfont iconfuzhi" type="button" data-clipboard-action="copy" data-clipboard-target="#copy${codeIndex}"></button>`;
          const linesLength = str.split(/\n/).length - 1;
          // 生成行号
          let linesNum = '<span aria-hidden="true" class="line-numbers-rows">';
          for (let index = 0; index < linesLength; index++) {
            linesNum = linesNum + "<span></span>";
          }
          linesNum += "</span>";
          if (lang && hljs.getLanguage(lang)) {
            // highlight.js 高亮代码
            const preCode = hljs.highlight(lang, str, true).value;
            html = html + preCode;
            if (linesLength) {
              html += '<b class="name">' + lang + "</b>";
            }
            // 将代码包裹在 textarea 中，由于防止textarea渲染出现问题，这里将 "<" 用 "<" 代替，不影响复制功能
            return `<pre class="hljs"><code>${html}</code>${linesNum}</pre><textarea style="position: absolute;top: -9999px;left: -9999px;z-index: -9999;" id="copy${codeIndex}">${str.replace(
              /<\/textarea>/g,
              "</textarea>"
            )}</textarea>`;
          }
        }
      });

      // 将markdown替换为html标签
      article.htmlContent = md.render(
        article.htmlContent ? article.htmlContent : article.htmlContent
      );
      this.article = article;
    },

    previewImg(img) {
      this.$imagePreview({
        images: this.imgList,
        index: this.imgList.indexOf(img)
      });
    },
    deleteHTMLTag(content) {
      return content
        .replace(/<\/?[^>]*>/g, "")
        .replace(/[|]*\n/, "")
        .replace(/&npsp;/gi, "");
    },
    getHandlerArticleSummary(str) {
      return handlerArticleSummary(str)
    },

    getHandlerArticleTitle(str) {
      return handlerArticleTitle(str)
    },
  },
  computed: {
    articleCover() {
      return (
        "background: url(" +
        this.article.cover +
        ") center center / cover no-repeat"
      );
    },

    isFull() {
      return function(id) {
        return id ? "post full" : "post";
      };
    }
  }
};
</script>

<style scoped>
.banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.article-info i {
  font-size: 14px;
}

.article-info {
  font-size: 14px;
  line-height: 1.9;
  display: inline-block;
}

@media (min-width: 760px) {
  .banner {
    color: #eee !important;
  }

  .article-info span {
    font-size: 95%;
  }

  .article-info-container {
    position: absolute;
    bottom: 6.25rem;
    padding: 0 8%;
    width: 100%;
    text-align: center;
  }

  .second-line,
  .third-line {
    display: inline;
  }

  .article-title {
    font-size: 35px;
    margin: 20px 0 8px;
  }

  .pagination-post {
    display: flex;
  }

  .post {
    width: 50%;
  }

  .recommend-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 3px;
    width: calc(33.333% - 6px);
    height: 200px;
    background: #000;
    vertical-align: bottom;
  }
}

@media (max-width: 759px) {
  .banner {
    color: #eee !important;
    height: 360px;
  }

  .article-info span {
    font-size: 90%;
  }

  .separator:first-child {
    display: none;
  }

  .blog-container {
    margin: 322px 5px 0 5px;
  }

  .article-info-container {
    position: absolute;
    bottom: 1.3rem;
    padding: 0 5%;
    width: 100%;
    color: #eee;
    text-align: left;
  }

  .article-title {
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
  }

  .post {
    width: 100%;
  }

  .pagination-post {
    display: block;
  }

  .recommend-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 3px;
    width: calc(100% - 4px);
    height: 150px;
    margin: 2px;
    background: #000;
    vertical-align: bottom;
  }
}

.article-content {
  word-break: break-word;
  font-size: 14px;
  line-height: 2;
}

.article-operation {
  display: flex;
  align-items: center;
}

.article-category a {
  color: #fff !important;
}

.tag-container a {
  display: inline-block;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0 0.75rem;
  width: fit-content;
  border: 1px solid #49b1f5;
  border-radius: 1rem;
  color: #49b1f5 !important;
  font-size: 12px;
  line-height: 2;
}

.tag-container a:hover {
  color: #fff !important;
  background: #49b1f5;
  transition: all 0.5s;
}

.aritcle-copyright {
  position: relative;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  line-height: 2;
  padding: 0.625rem 1rem;
  border: 1px solid #eee;
}

.aritcle-copyright span {
  color: #49b1f5;
  font-weight: bold;
}

.aritcle-copyright a {
  text-decoration: underline !important;
  color: #99a9bf !important;
}

.aritcle-copyright:before {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background: #49b1f5;
  content: "";
}

.aritcle-copyright:after {
  position: absolute;
  top: 0.95rem;
  right: 0.95rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5em;
  background: #fff;
  content: "";
}

.article-reward {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-btn {
  position: relative;
  display: inline-block;
  width: 100px;
  background: #49b1f5;
  margin: 0 1rem;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.reward-btn:hover .reward-main {
  display: block;
}

.reward-main {
  display: none;
  position: absolute;
  bottom: 40px;
  left: 0;
  margin: 0;
  padding: 0 0 15px;
  width: 100%;
}

.reward-all {
  display: inline-block;
  margin: 0 0 0 -110px;
  padding: 20px 10px 8px !important;
  width: 320px;
  border-radius: 4px;
  background: #f5f5f5;
}

.reward-all:before {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}

.reward-all:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 2px;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 13px solid #f5f5f5;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
}

.reward-item {
  display: inline-block;
  padding: 0 8px;
  list-style-type: none;
}

.reward-img {
  width: 130px;
  height: 130px;
  display: block;
}

.reward-desc {
  margin: -5px 0;
  color: #858585;
  text-align: center;
}

.like-btn {
  display: inline-block;
  width: 100px;
  background: #969696;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.like-btn-active {
  display: inline-block;
  width: 100px;
  background: #ec7259;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.pagination-post {
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
  background: #000;
}

.post {
  position: relative;
  height: 150px;
  overflow: hidden;
}

.post-info {
  position: absolute;
  top: 50%;
  padding: 20px 40px;
  width: 100%;
  transform: translate(0, -50%);
  line-height: 2;
  font-size: 14px;
}

.post-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.6s;
  object-fit: cover;
}

.post a {
  position: relative;
  display: block;
  overflow: hidden;
  height: 150px;
}

.post:hover .post-cover {
  opacity: 0.8;
  transform: scale(1.1);
}

.label {
  font-size: 90%;
  color: #eee;
}

.post-title {
  font-weight: 500;
  color: #fff;
}

hr {
  position: relative;
  margin: 40px auto;
  border: 2px dashed #d2ebfd;
  width: calc(100% - 4px);
}

.full {
  width: 100% !important;
}

.right-container {
  padding: 20px 24px;
  font-size: 14px;
}

.right-title {
  display: flex;
  align-items: center;
  line-height: 2;
  font-size: 16.8px;
  margin-bottom: 6px;
}

.right-title i {
  font-weight: bold;
}

.recommend-container {
  margin-top: 40px;
}

.recommend-title {
  font-size: 20px;
  line-height: 2;
  font-weight: bold;
  margin-bottom: 5px;
}

.recommend-cover {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.6s;
  object-fit: cover;
}

.recommend-info {
  line-height: 2;
  color: #fff;
  position: absolute;
  top: 50%;
  padding: 0 20px;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
  font-size: 14px;
}

.recommend-date {
  font-size: 90%;
}

.recommend-item:hover .recommend-cover {
  opacity: 0.8;
  transform: scale(1.1);
}

.article-item {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.article-item:first-child {
  padding-top: 0;
}

.article-item:last-child {
  padding-bottom: 0;
}

.article-item:not(:last-child) {
  border-bottom: 1px dashed #f5f5f5;
}

.article-item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}

.article-item img:hover {
  transform: scale(1.1);
}

.content {
  flex: 1;
  padding-left: 10px;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.content-cover {
  width: 58.8px;
  height: 58.8px;
  overflow: hidden;
}

.content-title a {
  transition: all 0.2s;
  font-size: 95%;
}

.content-title a:hover {
  color: #2ba1d1;
}

.content-time {
  color: #858585;
  font-size: 85%;
  line-height: 2;
}

</style>

<style lang="scss">
pre.hljs {
  padding: 12px 2px 12px 40px !important;
  border-radius: 5px !important;
  position: relative;
  font-size: 14px !important;
  line-height: 22px !important;
  overflow: hidden !important;

  &:hover .copy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  code {
    display: block !important;
    margin: 0 10px !important;
    overflow-x: auto !important;

    &::-webkit-scrollbar {
      z-index: 11;
      width: 6px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 6px;
      background: #666;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
      background: #1e1e1e;
    }

    &::-webkit-scrollbar-track-piece {
      background: #1e1e1e;
      width: 6px;
    }
  }

  .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 12px;
    bottom: 12px;
    left: 0;
    font-size: 100%;
    width: 40px;
    text-align: center;
    letter-spacing: -1px;
    border-right: 1px solid rgba(0, 0, 0, 0.66);
    user-select: none;
    counter-reset: linenumber;

    span {
      pointer-events: none;
      display: block;
      counter-increment: linenumber;

      &:before {
        content: counter(linenumber);
        color: #999;
        display: block;
        text-align: center;
      }
    }
  }

  b.name {
    position: absolute;
    top: 7px;
    right: 45px;
    z-index: 1;
    color: #999;
    pointer-events: none;
  }

  .copy-btn {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    color: #ccc;
    background-color: #525252;
    border-radius: 6px;
    display: none;
    font-size: 14px;
    width: 32px;
    height: 24px;
    outline: none;
  }
}

.showInline {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
</style>
