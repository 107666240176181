
const MAX_ARTICLE_SUMMARY_LENGTH = 55;
const MAX_ARTICLE_TITLE_LENGTH = 32;

export function handlerArticleSummary(str) {
    if (!str) {
        return str;
    }

    if (str.length > MAX_ARTICLE_SUMMARY_LENGTH) {
        return str.substr(0, MAX_ARTICLE_SUMMARY_LENGTH) + "..."
    }
    return str;
}

export function handlerArticleTitle(str) {
    if (!str) {
        return str;
    }

    if (str.length > MAX_ARTICLE_TITLE_LENGTH) {
        return str.substr(0, MAX_ARTICLE_TITLE_LENGTH) + "..."
    }
    return str;
}
