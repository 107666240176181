import request from "../utils/request";

// 获取文章评论列表
export function getCommentList(data) {
    return request({
        url: '/article/api/comment/commentList',
        method: 'POST',
        data
    })
}

// 文章评论
export function sendComment(data) {
    return request({
        url: '/article/comment/sendComment',
        method: 'POST',
        data
    })
}
